<template>
  <v-dialog v-model="shower" scrollable persistent max-width="500px"  >
    <v-card>
      <v-card-title>{{$t('colorpiker')}}<v-spacer></v-spacer>
        <v-icon :color="color.theme" dense dark size="35">mdi-palette</v-icon> 
      </v-card-title>
      <v-divider></v-divider>
      <v-container :style="$vuetify.theme.dark ? 'background-color: #1E1E1E;' : 'background-color: white;'">
        <v-row>
          <v-col class="d-flex justify-center">
            <v-color-picker v-model="colors"></v-color-picker>
          </v-col>
        </v-row>
      </v-container> 
      <v-divider></v-divider>
      <v-card-actions> 
        <v-spacer></v-spacer>
        <v-btn color="red" outlined right @click="$emit('close')">{{$t('changeName.close')}}</v-btn>
        <v-btn :color="color.theme" :dark="color.darkTheme" right @click="changecolor(showColor),$emit('close')">{{$t('changeName.submit')}}</v-btn>          
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { validationMixin } from 'vuelidate'
import { required, maxLength, email } from 'vuelidate/lib/validators'
export default {
     props: ['show'],    
     data: () => ({
        hsla: { h: 300, s: 1, l: 0.5, a: 1 },
       setcolor:'',
       mode:'hsla',
       modes: ['hsla'],
       }
       ),
     
    //  #000000FF
     computed: {
      ...mapState(['username', 'authorize','account_active','color']),
      ...mapState({processloader: 'loading'}),
      ...mapGetters(['dataUsername', 'dataAuthorize','dataAccountActive', 'dataAccesstoken', 'dataBusinessProfile','dataCitizenProfile', 'dataDepartmentAccessId','dataAccountId']),
        shower :{
            get() {
                if(this.show === true){
                  this.$emit('closeDrag'); 
                }
                return this.show
            },
            set (value) {
                
            }
        },
        colors: {
        get () {
          return this['hsla']
        },
        set (v) {
          this['hsla'] = v
        },
      },
      showColor () {
        console.log(this.colors.h);
        if (typeof this.colors === 'string') return this.colors

        return JSON.stringify(Object.keys(this.colors).reduce((colors, key) => {
          colors[key] = Number(this.colors[key].toFixed(2))
          return colors
        }, {}), null, 2)
      },
    //  showColor () {
        // console.log(this.mode);
      //   if (typeof this.colors === 'string') return this.colors
      //   return JSON.stringify(Object.keys(this.colors).reduce((colors, key) => {
      //     colors[key] = Number(this.color[key].toFixed(2))
      //     console.log("color",colors);
      //     return colors
      //   }, {}), null, 2)
      //       this.setcolor=colors;
      //   console.log("color",colors);
        
      // let color= "hsla("+String(this.colors.h)+","+String(this.colors.s)+","+String(this.colors.l)+","+String(this.colors.a)+")"
      // let color_code ="hsla("+String(this.colors.h)+","+String(this.colors.s)+","+String(0.9)+","+String(this.colors.a)+")"
      // localStorage.setItem("color_code", color_code);
      // console.log(color);
      // return color
      // },
    },
    methods: {
      changecolor(parameter) {
        console.log(parameter);
        //console.log(this.colors_.hsla["h"],this.colors_.hsla["s"],this.colors_.hsla["l"],this.colors_.hsla["a"]);
        let color_code = "hsla("+String(this.colors.h)+","+String(this.colors.s*100)+"%,"+String(97)+"%,"+String(this.colors.a)+")"
        console.log(color_code);
        localStorage.setItem("color_code_temp", color_code);
        localStorage.setItem("color_code", this.$vuetify.theme.dark ? "#333333" : color_code);
        //console.log(parameter);
        let color = "hsla("+String(this.colors.h)+","+String(this.colors.s*100)+"%,"+String(this.colors.l*100)+"%,"+String(this.colors.a)+")"
        localStorage.setItem("color", color);
        this.$store.dispatch("check_color",localStorage.getItem("color")).then(res => {});     
      },
    }
}
</script>
<style>

</style>